/** @jsx jsx */
import { jsx } from "theme-ui"

import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"

export const NoImage = () => {
  return (
    <StaticQuery
      query={graphql`
        query NoImageQuery {
          noimage: file(name: { eq: "no-image" }) {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            sx={{
              position: "relative",
              backgroundColor: "grey",
              backgroundSize: "cover",
              backgroundP: "top",
            }}
          >
            <GatsbyImage
              layout="constrained"
              image={data.noimage.childImageSharp.gatsbyImageData}
              aspectRatio={20 / 13}
              alt="No Image"
              title="No Image"
              sx={{
                img: {
                  objectFit: "unset !important",
                },
              }}
            />
          </div>
        )
      }}
    />
  )
}
