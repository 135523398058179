/** @jsx jsx */
import { jsx } from "theme-ui"
import { connectRefinementList } from "react-instantsearch-dom"
import Select from "react-select"

const ConsumerStatus = ({ ref, className, items, refine }) => {
  const handleChange = data => {
    if (data[0]) {
      const selectedCounties = []
      data.forEach(element => {
        selectedCounties.push(element.value)
      })
      refine(selectedCounties)
    } else {
      refine(["for-sale"])
    }
  }
  const options = items.map(item => ({
    value: item.label,
    label: item.label.replace(/-/g, " "),
  }))

  return (
    <Select
      className={className}
      ref={ref}
      options={options}
      isSearchable={true}
      isMulti
      onChange={handleChange}
      defaultValue={{ label: "For Sale", value: "for-sale" }}

      // value={options.filter(option => option.label === "Some label")}
      // placeholder="For Sale"
    />
  )
}

const CustomRefinementListStatus = connectRefinementList(ConsumerStatus)

export default CustomRefinementListStatus
