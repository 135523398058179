/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { NoImage } from "./noImage"

import { truncate } from "../../../utils/stringUtils"
import Pin from "../../atoms/Pin"

import Antler from "../../../images/SIM_card_header.png"

const Ribbon = ({ color, label }) => (
  <div
    sx={{
      color: "#ffffff",
      fontSize: "1rem",
      position: "absolute",
      right: "26px",
      bottom: "-24px",
      zIndex: "1",
    }}
  >
    <div
      sx={{
        clipPath: "polygon(93% 23%, 100% 50%, 93% 77%, 0 77%, 5% 50%, 0 23%)",
        backgroundColor: color,
        // width: "190px",
        padding: "0px 20px",
        minWidth: "60px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {label}
    </div>
  </div>
)

const PropertyTeaser = ({ property, asModal }) => {
  let slugPath = ""
  if (property.slug) {
    if (property.slug.current) {
      slugPath = "/listings/" + property.slug.current
    } else {
      slugPath = "/listings/" + property.slug
    }
    if (asModal) {
      slugPath = slugPath + "?isModal=true"
    }
  }

  let price = property.price
  if (typeof property.price === "string") {
    price = parseFloat(property.price)
  }
  let acreage = property.acreage
  if (acreage) {
    if (acreage > 0) {
      acreage =
        acreage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " acres"
    } else {
      acreage = ""
    }
  } else {
    acreage = ""
  }
  return (
    <div
      sx={{
        height: "100%",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
        letterSpacing: "0px",
      }}
    >
      <Link
        state={{
          noScroll: true,
          modal: asModal,
        }}
        to={slugPath}
        title="Property"
        // onClick={() => {}}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          {property.displayStatus === "reduced" ||
          property.displayStatus === "Reduced" ? (
            <Ribbon color="#189A6D" label="Reduced" />
          ) : property.displayStatus === "under-contract" ||
            property.displayStatus === "Active Under Contract" ? (
            <Ribbon color="#B5941F" label="Under Contract" />
          ) : property.displayStatus === "for-sale" ||
            property.displayStatus === "Active" ? (
            <Ribbon color="#18459A" label="For Sale" />
          ) : property.displayStatus === "buyer-represented" ? (
            <Ribbon color="#4F55A1" label="Buyer Represented" />
          ) : property.displayStatus === "Pending" ? (
            <Ribbon color="#189a6d" label="Pending" />
          ) : property.displayStatus === "just-sold" ? (
            <Ribbon color="#B5701F" label="Just Sold" />
          ) : property.displayStatus === "sold" ? (
            <Ribbon color="#9B3708" label="Sold" />
          ) : (
            <div></div>
          )}
          <div
            sx={{
              textAlign: "center",
              img: {
                maxWidth: "100%",
                height: "auto",
              },
            }}
          >
            {property.sanityimage && (
              <div
                sx={{
                  position: "relative",
                }}
              >
                <div
                  className="teaser-antler"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    right: "10px",
                    zIndex: "2",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    // width: "20%",
                    maxWidth: ["120px", "75px", "75px"],
                  }}
                >
                  <div>
                    <img src={Antler} />
                  </div>
                </div>

                <GatsbyImage
                  layout="constrained"
                  image={property.sanityimage.asset.gatsbyImageData}
                  aspectRatio={20 / 13}
                  alt="Property Image"
                  title="Property Image"
                  sx={{
                    img: {
                      objectFit: "unset !important",
                    },
                  }}
                />
              </div>
            )}
            {property.image && (
              <div
                alt="Property Image"
                title="Property Image"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  img: {
                    aspectRatio: "20 / 13",
                    maxWidth: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  sx={{
                    display: "block",
                  }}
                  src={property.image}
                />
              </div>
            )}
            {!property.sanityimage && !property.image && <NoImage />}
          </div>
        </div>
        <div
          sx={{
            flexGrow: "1",
            display: "flex",
            flexDirection: "column",
            flex: ["1", "1", "1"],
            minHeight: "110px",
          }}
        >
          <h3
            sx={{
              color: "grayHvy",
              fontSize: "24px !important",
              lineHeight: "1.5rem !important",
              margin: "26px 26px 10px 26px !important",
              fontFamily: "body",
              textTransform: "uppercase",
              letterSpacing: "0px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontWeight: "500",
              maxWidth: ["260px", "500px"],
            }}
          >
            {property.address}
          </h3>
          <div
            sx={{
              display: "flex",
              padding: "0px 26px 15px 26px",
              color: "grayHvy",
              justifyContent: "space-between",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Pin />
              {property.sanitycounty && property.sanitycounty.countyName}
              {property.county && property.county} County
            </div>
            {acreage && <div>{acreage}</div>}
          </div>
        </div>

        <div
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",

            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <div
            sx={{
              padding: "17px 26px 17px 26px",
              color: "grayHvy",
              fontSize: "1rem",
              minHeight: "66px",
            }}
          >
            {truncate(property.description)}{" "}
          </div>

          <div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "davisGrayLight",
              color: "white",
              fontWeight: "500",
              fontSize: "24px",
              letterSpacing: "0px",
            }}
          >
            {property.price && (
              <div style={{ padding: "12px 26px 10px", fontWeight: "400" }}>
                {property.displayStatus === "sold"
                  ? "SOLD"
                  : price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PropertyTeaser
