/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../layout/header"
import Footer from "../layout/footer"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "./serializers/serializers"
import SearchResults from "../components/searchResults"
import Container from "../components/container"
import Hero from "../layout/Hero"
const LayoutSearch = home => {
  const data = useStaticQuery(graphql`
    query SiteSearchTitleQuery {
      site: site {
        siteMetadata {
          title
        }
      }
      blockFragment: sanityPageDefinition(slug: { current: { eq: "home" } }) {
        _rawEntities(resolveReferences: { maxDepth: 10 })
        _rawHero(resolveReferences: { maxDepth: 10 })
      }
    }
  `)
  return (
    <div
      sx={{
        fontFamily: "body",
        fontWeight: "body",
        fontSize: "2",
        lineHeight: "body",
        h1: {
          fontFamily: "heading",
          fontWeight: "heading",
          lineHeight: "heading",
        },
        h2: {
          fontFamily: "heading",
          fontWeight: "heading",
          lineHeight: "heading",
          fontSize: "6",
        },
      }}
    >
      <h2 style={{ display: "none" }}>Search Items</h2>
      <div>
        <Hero node={data.blockFragment}></Hero>
      </div>
      <SearchResults />

      <Footer></Footer>
    </div>
  )
}

export default LayoutSearch
