/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../../components/serializers/serializers"
import Layout from "../../layout"
import LayoutSearch from "../../components/layoutSearch"
import Seo from "../../components/seo"
import Hero from "../../layout/Hero"
import Header from "../../layout/header"

const PageContainer = ({ node }) => {
  const metaTitle = node.metaTitle ? node.metaTitle : node.title
  return (
    <>
      <div id="modal-parent" />
      <div id={node.slug.current}>
        <Seo title={metaTitle} description={node.metaDescription}></Seo>
        {node.slug.current === "home" && (
          <h1 style={{ display: "none" }}>Texas Ranches for Sale</h1>
        )}
        {node.slug.current === "home" && (
          <div>
            <LayoutSearch home={true} />
          </div>
        )}
        {node.slug.current !== "home" && (
          <Layout>
            {node._rawHero ? <Hero node={node} /> : <Header />}

            <div
              sx={{
                display: ["flex", "block", "block"],
                maxWidth: ["100%", "100%", "100%", "100%"],
                padding: ["0px", "0px", "0px"],
                boxSizing: "content-box",
                justifyContent: "space-between",
                flexDirection: ["column", "row", "row"],
              }}
            >
              <div
                sx={{
                  width: ["100%", "100%", "100%"],
                  maxWidth: "860px",
                  margin: "0 auto",
                }}
              >
                {node._rawHeroImage && (
                  <div
                    sx={{
                      figure: {
                        margin: "0px",
                        display: "flex",
                      },
                    }}
                  >
                    <BlockContent
                      blocks={node._rawHeroImage}
                      serializers={Serializers}
                    />
                  </div>
                )}
                <div
                  className="main-body"
                  sx={{
                    padding: ["40px 5%", "40px 3%", "40px 3%"],
                    boxSizing: "border-box",
                    h1: {
                      marginTop: "0px",
                    },
                    "h1,h2,h3,h4,h5,h6": {
                      color: "#484242",
                      fontWeight: "400",
                    },
                    li: {
                      marginBottom: "20px",
                    },
                  }}
                >
                  {node._rawEntities && (
                    <div
                      className="tester"
                      sx={{
                        "section > div": {
                          padding: "0px !important",
                        },
                      }}
                    >
                      <BlockContent
                        blocks={node._rawEntities}
                        serializers={Serializers}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Layout>
        )}
      </div>
    </>
  )
}
export default PageContainer
