import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Pin = () => {
  const data = useStaticQuery(query)
  return (
    <GatsbyImage
      sx={{
        width: "8px",
        marginRight: "5px",
      }}
      height={9}
      image={data.pin.childImageSharp.gatsbyImageData}
      alt="Map pin"
      title="Map pin"
    />
  )
}

export default Pin

const query = graphql`
  query PinQuery {
    pin: file(name: { eq: "pin" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
