/** @jsx jsx */
import { jsx } from "theme-ui"
import algoliasearch from "algoliasearch/lite"
import qs from "qs"
import { useRef, useState, useEffect } from "react"
import {
  InstantSearch,
  Configure,
  connectInfiniteHits,
  connectStats,
} from "react-instantsearch-dom"

import PropertyTeaser from "../entity/property/propertyTeaser"
import withStorageSync from "../../templates/StorageSync"
import Container from "../../components/container"
import CustomRefinementList from "./Consumer"
import CustomRefinementListStatus from "./ConsumerStatus"
import CustomRefinementListRegions from "./ConsumerRegions"

const InfiniteHits = ({ hits, hasMore, refineNext }) => (
  <div>
    <ul className="ais-InfiniteHits-list">
      {hits.map(hit => (
        <li
          sx={{
            boxSizing: "border-box",
            zIndex: "1",
            position: "relative",
            backgroundColor: "white",
            marginBottom: "40px",
          }}
          key={hit.objectID}
        >
          <HitComponent hit={hit} />
        </li>
      ))}
    </ul>
    <button
      sx={{
        display: hasMore ? "block" : "none",
        backgroundColor: "#9FC9F4",
        border: "none",
        color: "davisGray",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: "400",
        margin: "0 auto",
        padding: "10px 20px",
        borderRadius: "18px",
      }}
      disabled={!hasMore}
      onClick={refineNext}
    >
      Click to load more properties
    </button>
  </div>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_ADMIN_API_KEY
)

const SearchResults = props => {
  const selectInputRef = useRef()
  const statusRadioRef = useRef()
  const regionsRef = useRef()

  const [searchStateCount, setSearchStateCount] = useState(0)
  const [searchChange, setSearchChange] = useState(false)
  const [searchState, setSearchState] = useState({ ...props.searchState })
  const [clearSearchState, setClearSearchState] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      const search =
        window.location.pathname + "?" + qs.stringify(props.searchState)
      localStorage.setItem("searchState", search)
    }

    // For clearing search results on Page reload
    window.addEventListener("beforeunload", event => {
      localStorage.clear()
    })
  }, [])

  useEffect(() => {
    if (searchStateCount < 1) {
      setSearchStateCount(searchStateCount + 1)
      setSearchChange(false)
    } else {
      setSearchChange(true)
    }
  }, [props.searchState])
  useEffect(() => {
    if (clearSearchState) {
      var newSearchState = props.searchState
      setSearchState(newSearchState)
      setClearSearchState(false)
      setSearchChange(false)
    } else {
      setSearchState(props.searchState)
    }
  }, [props.searchState])

  // useEffect(() => {
  //   customClear()
  // }, [])

  const customClear = () => {
    var newSearchState = []
    setSearchState(newSearchState)
  }

  const Stats = ({ nbHits }) => <span>{nbHits} </span>
  const CustomStats = connectStats(Stats)

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="additional_properties"
      searchState={searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
      stalledSearchDelay="200"
    >
      <div
        id="search-results"
        sx={{
          backgroundColor: "davisGrayLight",
          padding: "60px 0px 40px 0px",
        }}
      >
        <Container>
          <h2
            sx={{
              color: "white",
              margin: "0px 0px 20px 0px",
              fontSize: ["2.5rem !important", "3rem !important"],
            }}
          >
            Featured Listings
          </h2>
          <div id="filters">
            <div
              sx={{
                color: "white",
                marginBottom: ["1rem", "10px"],
              }}
            >
              Showing {searchChange && <CustomStats />}Results For:
            </div>
            <div
              sx={{
                display: "flex",
                gap: [".5rem", "2rem"],
                flexDirection: ["column", "row", "row"],

                color: "white",
                textTransform: "capitalize",
                maxWidth: ["calc(100% - 20px)", "100%"],
                "div[class*='multiValue']": {
                  backgroundColor: "transparent",
                  border: "2px solid white",
                  borderRadius: "11px",
                },
                "> div": {
                  display: ["block", "flex"],
                  alignItems: "center",
                  marginBottom: ["10px", "0px", "0px"],

                  // justifyContent: ["space-between"],
                  // width: "100%",
                },

                ".filter-select": {
                  "> div": {
                    backgroundColor: "davisGray",
                    border: "0px",
                    width: ["100%", "205px"],
                    color: "white !important",
                    "&:nth-child(3)": {
                      marginRight: "0px",
                    },
                    div: {
                      color: "white !important",
                    },
                    "> div > div": {
                      "div:nth-child(2)": {
                        ":hover": {
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                        },
                      },
                    },
                    "> div:nth-child(2)": {
                      backgroundColor: "spanishGray",
                      width: "40px !important",
                      span: {
                        display: "none",
                      },
                      path: {
                        color: "text",
                      },
                      "> div": {
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        path: {
                          color: "white !important",
                        },
                      },
                    },
                  },
                },
              }}
            >
              <div>
                <div
                  sx={{
                    marginRight: "10px",
                    marginBottom: ["10px", "0px", "0px"],
                  }}
                >
                  Status:
                </div>
                <CustomRefinementListStatus
                  attribute="status"
                  ref={statusRadioRef}
                  className="filter-select"
                  defaultRefinement={["for-sale"]}
                />
              </div>
              <div>
                <div
                  sx={{
                    marginRight: "1rem",
                    marginBottom: ["10px", "0px", "0px"],
                  }}
                >
                  Counties:
                </div>
                <span>
                  <CustomRefinementList
                    id="countyselect"
                    ref={selectInputRef}
                    className="filter-select"
                    attribute="county"
                    operator="or"
                    limit={1000}
                  />
                </span>
              </div>
              {/* <div>
                <div
                  sx={{
                    marginRight: "10px",
                    marginBottom: ["10px", "0px", "0px"],
                  }}
                >
                  Regions:
                </div>
                <CustomRefinementListRegions
                  attribute="region.regionName"
                  ref={regionsRef}
                  className="filter-select"
                />
              </div> */}
            </div>
          </div>
        </Container>
      </div>

      <div
        sx={{
          backgroundColor: "spanishGray",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Container noMobilePadding={true}>
          <Configure hitsPerPage={9} />
          <CustomInfiniteHits />
        </Container>
      </div>
    </InstantSearch>
  )
}

function HitComponent({ hit }) {
  return (
    <PropertyTeaser property={hit} className="hit" asModal={true}>
      {hit.price}
    </PropertyTeaser>
  )
}

export default withStorageSync(SearchResults)
